:root {
  --animate-duration: 300ms;
}
html, body {
  width: 100%;
  padding: 0;
  margin: 0;
}
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #464646;
  background-color: #FAFAFA;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.01em;
  font-size: 14px;
  line-height: 1.5em;
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.right-panel{
  position: absolute;
  width: 800px;
  height: 100%;
  height: 100vh;
  left: 1558px;
  bottom: 0;
  background-color: #009CB9;
}
h1, h2, h3 {
  color: #2d2d2d;
  line-height: 1.2em;
  text-shadow: 0px 0px 2px #d6d6d6;
}
.link {
  cursor: pointer;
  text-decoration: none;
  color: #558CC1;
}
.link:hover {
  text-decoration: underline;
}
.gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ededed+0,f4f4f4+100 */
  background: rgb(237,237,237); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(237,237,237,1) 0%, rgba(244,244,244,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(237,237,237,1) 0%,rgba(244,244,244,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(237,237,237,1) 0%,rgba(244,244,244,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiInputBase-root {
  width: 100% !important;
}
#root, .root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
}
p {
  font-size: 1.15em;
}
a {
  margin-right: 16px;
  color: #558CC1;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.container {
  overflow: hidden;
  width: 800px;
  padding: 0 16px;
}
.sticky .container {
  width: 1170px;
}
section .container, .welcome .container{
  width: 1170px;
  padding: 0 36px;
}
section {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
}
section h1 {
  color: #ffffff;
  font-weight: 300;
  font-size: 36px;
}
section h2 {
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  color: #656565;
}

#welcome {
  background-color: #009CB9;
  color: #ffffff;
  font-weight: 300;
}
section#welcome {
  margin-top: 0;
  padding-top: 0;
}
#welcome .screenshot {
  /*height: 600px; */
}
#welcome .box:first-child {
  margin-right: 50px;
}
.app-description {
  color: #ffffff;
  font-weight: 300;
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
}
footer {
  margin: 36px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #aaaaaa;
}
footer .container, footer.welcome .container {
  padding: 16px 0;
  margin: 0 16px;
  border-top: 1px solid #eeeeee;
}
footer a {
  color: #999999;
  text-decoration: none;
}
main {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
header {
  background-image: url("./assets/img/bg2.png");
  background-position: center -10px;
  background-repeat: no-repeat;
  background-size: 110% 100px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 100;
  transition: all 0.5s ease-out;
}
header.sticky {
  position: fixed;
  top:0;
  left: 0;
}
header .container {
  height: 70px;
  padding-top: 8px;
  padding-bottom: 8px;
}
header.welcome {
  background: none;
  background-color: #009CB9;
}
header.welcome a.header {
  margin-left: 6px;
}
header.welcome .container{
  height: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
header.welcome img {
  height: 36px;
  margin: 0;
}
header img{
  height: 30px;
  margin: 8px 0;
}
#features {
  /* font-weight: 300; */
}
#screenshots {
  background-color: #ffffff;
}
#screenshots .wrapper{
  /*
   style={{width: '90%', margin: '0 auto'}}
   992px
   */
  width: 90%;
  margin: 0 auto;
}
#screenshots .slick-prev:before, #screenshots .slick-next:before {
  color: #616161;
}
#screenshots .slick-slider {
  width: 100%;
}
#screenshots img {
  width: 100%;
}
#screenshots .img-wrapper {
  position: relative;
}
#screenshots .img-wrapper .transparent{
  visibility: hidden;
}
#screenshots .img-wrapper > div{
  box-sizing: border-box;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all 0.5s ease-out;
  padding: 64px;
}
#screenshots .img-wrapper:focus {outline:0;}
#screenshots .slick-current .img-wrapper > div{
  padding: 16px;
}
.boxes {
  display: flex;
  margin: 24px 0;
  flex-direction: row;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.feature-box {
  padding: 16px 0;
  flex: 1;
  margin: 0 24px;
}
.feature-box:first-child {
  margin-left: 0;
}
.feature-box:last-child {
  margin-right: 0;
}
.feature-box .description {
  padding: 8px 0;
  text-align: center;
  font-size: 16px;
}
.feature-box img {
  border-radius: 36px;
  text-align: center;
  width: 100%;
  max-width: 440px;
  box-shadow: 0px 0px 1px rgba(0,0,0,0.2);
}
.feature-box .image {
  padding: 16px;
  text-align: center;
}
.feature-box h3 {
  text-align: center;
  margin: 8px 0;
  font-size: 24px;
  font-weight: 400;
  color: #a27c4b;
}
.download-box {
  margin: 36px 0;
}
.download-box span{
  font-size: 14px;
}
.login-wrapper {
  padding: 24px 36px;
  background-color: #ffffff;
}
.login-content{
  display: flex;
  flex-direction: row;
}
.login-wrapper .divider {
  width: 1px;
  height: 200px;
  align-self: center;
  background-color: #e7e7e7;
  margin: 0 36px;
}
.login-content > div:first-child {
  flex: 1;
}
.login-content > div:last-child {
  flex: 1;
}

@media (max-width: 1170px) {
  section .container, .welcome .container{
    width: 100%;
  }
  #screenshots .wrapper {
    width: 95%;
  }
}
@media (max-width: 768px) {
  header.welcome a.header {
    margin-left: 0;
  }
  #welcome .box:first-child {
    display: none;
  }
}
@media (max-width: 800px) {
  header {
    background-size: 130% 100px;
  }
  .modal.container {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .modal.container {
    height: 100%;
    top: 0;
  }
  .login-content {
    flex-direction: column;
  }
  .login-wrapper .divider {
    display: none;
  }
  .login-wrapper {
    padding: 24px 16px;
  }
  .app-description  {
    margin: 0;
  }
  .boxes {
    flex-direction: column;
  }
  .feature-box {
    margin: 0 0 16px 0;
  }
  section .container, .welcome .container{
    padding: 0 24px;
  }
  section#welcome {
    padding-bottom: 30px;
  }
  section .boxes {
    margin: 0;
  }
  #screenshots .slick-slider {

  }
}
@media (max-width: 300px) {

  header {
    background-size: 150% 100px;
  }
  .app-description  {
    margin: 0;
  }
  .boxes {
    flex-direction: column;
  }
  .feature-box {
    margin: 0 0 16px 0;
  }
}
/*
  display: flex;
  align-items: center;
  justify-content: center;
 */
